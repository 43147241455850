export default {
  API_ROOT: "api.treasureisland.greentubepro.com",
  TITLE: "playTIcasino",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "LuiW5uKm3dWKqQ5TVFvBjq17KM3wcxZN",
  APPLICATION_TARGET: "ti",
  APPLICATION_NAME: "playTIcasino",
  GOOGLE_CLIENT_ID: "421085647412-lf3of2d49080pi3lpb1ru3s87f02kgvn.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "prod",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://treasureisland.onelink.me/1865833017/9bv8qrtg",
  HELP_LINK: "playticasinohelp.zendesk.com",
  SITE_KEY: "6Le22jcUAAAAAJoEKGduRlplqw7scLoVxpwTjo1n",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "false",
  APPLE_STORE_URL: undefined,
  ANDROID_STORE_URL: undefined,
  DSDK_APP_TOKEN: "4247f272-0296-43cf-9234-be32bcda1fde",
  DSDK_HASH_KEY: "9A11232CCB9A599AF0FBF",
  DSDK_SV_URL: "https://a.bluebat.dive.games/TI",
  DSDK_API_URL: "https://api.bluebat.dive.games",
  DSDK_DOMAIN: "www.playticasino.com",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "www.playticasino.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "13",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://playticasinohelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_PROPERTY_SELECTOR: "false",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "false",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-5HV52DC",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "TEXT",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: undefined,
  FACEBOOK_LOGIN: "false",
  COMING_SOON: "false",
  MOBILE_WEB_SUPPORT: undefined
};